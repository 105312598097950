<template>
  <footer class="asf-checkout-footer">
    <AsfWrapper>
      <div class="asf-checkout-footer__content">
        <div class="asf-checkout-footer__left">
          <p class="asf-checkout-footer__support">
            <span>{{ $t('checkoutFooter.needHelp') }}</span>
            <AsfLink
              class="asf-checkout-footer__link"
              :link="`tel:${$t('global.phone')}`"
              :aria-label="$t('checkoutFooter.needHelp', 1)"
            >
              {{ $t('global.phone') }}
            </AsfLink>
          </p>
          <slot name="content-navigation" />
          <AsfList class="asf-checkout-footer__navigation" role="list">
            <AsfListItem v-for="(link, idx) in navigation" :key="idx" class="asf-checkout-footer__navigation-link">
              <slot name="navigation-link" :content="link" :class-names="['asf-link-secondary']">
                <AsfLink class="asf-link-secondary" :link="link.url">
                  {{ link.label }}
                </AsfLink>
              </slot>
            </AsfListItem>
          </AsfList>
        </div>
        <div class="asf-checkout-footer__right">
          <AsfSecureIcon :title="$t('checkoutFooter.iconText')" class="asf-checkout-footer__secure-icon" />
          <slot name="content-features" :content="image" :class-names="['asf-footer-payments__link']" />
        </div>
      </div>
      <div class="asf-checkout-footer__copy">
        <span>{{ $t('global.copyright', { copy: '&copy;', year: new Date().getFullYear().toString() }) }}</span>
      </div>
    </AsfWrapper>
  </footer>
</template>
<script lang="ts">
import { type AsfCheckoutFooterProps, MediaMaxBreakpoints } from '@ui/types'

export default defineComponent({
  name: 'AsfCheckoutFooter',
  props: {
    /** Defines array of navigation links with their properties */
    navigation: { type: Array as PropType<AsfCheckoutFooterProps['navigation']>, default: () => [] },
    /** Defines path to the payments icon */
    image: { type: Object as PropType<AsfCheckoutFooterProps['image']>, default: () => ({}) }
  },
  setup() {
    const { isMediaMatch: isSmallView } = useMediaQuery(MediaMaxBreakpoints.SM)
    return { isSmallView }
  }
})
</script>
<style lang="postcss">
@import '@components/organisms/CheckoutFooter/CheckoutFooter.css';
</style>
