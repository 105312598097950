<script setup lang="ts">
const { searchContent } = useContent()
const contentKey = 'checkout-footer'
const { data: content } = await useAsyncData(contentKey, async () => {
  return await searchContent({ keys: [contentKey] })
})
const navigation = computed(() => ({
  componentName: 'CheckoutFooterNavigation',
  links: (content?.value?.[contentKey]?.at(0)?.navigation ?? []) as any[]
}))
const features = computed(() => (content?.value?.[contentKey]?.at(0)?.features || []) as any[])
</script>
<template>
  <AsfCheckoutFooter>
    <template v-if="navigation.links?.length" #content-navigation>
      <ContentFooter :content="[navigation]" />
    </template>
    <template v-if="features.length" #content-features>
      <ContentFooter :content="features" />
    </template>
  </AsfCheckoutFooter>
</template>
