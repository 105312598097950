<template>
  <div id="checkoutLayout" class="asf-checkout-page" ref="pageLayoutRef">
    <AsfSkipToContent anchor="#pageContent" :content="$t('content.skip')" focus-first />
    <AsfSkipToContent anchor="#checkoutFooter" :content="$t('footer.skip')" focus-first />

    <LazyHydrationWrapper when-idle>
      <AsfCheckoutHeader :link="link" :phone-number="$t('global.phone')" />
    </LazyHydrationWrapper>
    <div id="pageContent" class="asf-checkout-content">
      <slot />
    </div>
    <LazyHydrationWrapper when-visible>
      <AppCheckoutFooter />
    </LazyHydrationWrapper>

    <AsfNotificationManager>
      <template v-slot="{ type, message, dismiss, displayTime }">
        <AsfNotification :appearance="type" :display-time="displayTime" @notification:hide="dismiss">
          {{ message }}
        </AsfNotification>
      </template>
    </AsfNotificationManager>
    <div class="asf-highlighter" ref="highlighterRef" />
    <div id="agreement-modal-placeholder" />
  </div>
</template>

<script lang="ts" setup>
const { getURL } = useURL()
const link = getURL({ name: 'index' })

const pageLayoutRef = ref(null)
const highlighterRef = ref(null)

useFocusHighlighter(pageLayoutRef, highlighterRef)
</script>
